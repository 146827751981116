import React from "react";

export const GlobalStateContext = React.createContext()
export const GlobalDispatchContext = React.createContext()

const initialState = {
    modalContent: null,
    searchParams: null,
    mainCategory: null
}

const reducer = (state, action) => {
    switch(action.type){
        case 'MODAL_CONTENT': {
            return {
                ...state,
                modalContent: action.payload
            }

        }

        case 'SEARCH_PARAMS': {
            return {
                ...state,
                searchParams: action.payload
            }

        }

        case 'MAIN_POST_CATEGORY': {
            return {
                ...state,
                mainCategory: action.payload
            }

        }

        default:
            throw new Error('Specified action type was not found')
    }
}

const GlobalContextProvider = ({children}) => {
    const [state, dispatch] = React.useReducer(reducer, initialState);
    return (
        <GlobalStateContext.Provider value={state}>
            <GlobalDispatchContext.Provider value={dispatch}>
                {children}
            </GlobalDispatchContext.Provider>
        </GlobalStateContext.Provider>
    )
}

export default GlobalContextProvider