import "tailwindcss/base.css";
import "tailwindcss/components.css";
import "tailwindcss/utilities.css";


import React from "react"
import GlobalContextProvider from "./src/context/GlobalContextProvider"

export const wrapRootElement = ({ element }) => {
  return <GlobalContextProvider>{element}</GlobalContextProvider>
}
